<template>
  <DiscountHeader
    :is-saving="isSaving"
    :is-editing="false"
    :has-updates="hasUpdates"
    :discount-name="t('discount.new-discount')"
    :collapsed-panels="panelCollapsed"
    @save-clicked="addNewDiscount"
    @cancel-clicked="routeToSearchPage"
    @on-toggle-all-panels="onToggleAllPanels()"
  />

  <div v-if="loading" class="c-overlay">
    <div class="relative top-175">
      <ProgressSpinner />
    </div>
  </div>

  <div>
    <Message
      v-for="discountCre of discountsCreated"
      :key="discountCre.id"
      class="mx-8 mb-8"
      severity="info"
      closable
      icon="pi pi-check"
      :life="60000"
    >
      {{ t("discount.created-message") }}
      <a class="underline" @click="routeToUpdatedDiscount">
        {{ discount.name }}
      </a></Message
    >
  </div>

  <div class="c-page-content mt-3 ml-4 pr-1">
    <div class="grid grid-cols-12 gap-6">
      <Card class="col-span-12 lg:col-span-8 mb-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <Name v-model:name="discount.name" />
              </div>
              <div>
                <StatusCheckbox id="discount-active" v-model:value="discount.active" :label="t(`discount.active`)" />
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <Description v-model:description="discount.description" />
              </div>

              <div>
                <div class="grid grid-cols-3 gap-4 pb-3">
                  <StatusCheckbox
                    id="discount-active-for-client"
                    v-model:value="discount.activeForClient"
                    :label="t(`discount.active-client`)"
                  />
                  <SelectClient v-model:client-id="discount.clientId" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4 mb-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <SelectDiscountType v-model:type="discount.discountType" />
              </div>
              <div>
                <CumulusDateTimePicker
                  id="active-from"
                  v-model:date-time="discount.activeFrom"
                  :label="t('discount.active-from')"
                  test-id="discount-active-from-date"
                  required
                ></CumulusDateTimePicker>
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <SelectDiscountRule
                  v-model:rule="discount.discountRule"
                  @discount-rule-changed="onDiscountRuleChanged"
                />
              </div>

              <div>
                <CumulusDateTimePicker
                  id="active-to"
                  v-model:date-time="discount.activeTo"
                  :label="t('discount.active-to')"
                  test-id="discount-active-to-date"
                  required
                ></CumulusDateTimePicker>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <Panel
      :header="t('discount.product.label')"
      toggleable
      :collapsed="panelCollapsedProduct"
      class="mb-4 rounded-2xl"
      :pt:header:on-click="() => (panelCollapsedProduct = !panelCollapsedProduct)"
    >
      <div class="col-span-12 lg:col-span-6 md:flex">
        <div class="col-span-12 md:col-span-3">
          <StatusCheckbox
            id="discount-active-for-all-products"
            v-model:value="discount.activeForAllProducts"
            :label="t(`discount.active-all-products`)"
            :disabled="true"
          />
        </div>
        <div class="col-span-12 md:col-span-4 w-1/5">
          <ProductSearch :discount-rule="discount.discountRule" @product-selected="addProductToDiscountList" />
        </div>
      </div>
      <div class="col-span-12 mt-2">
        <ProductList
          :products="selectedProducts"
          :discount-rule="discount.discountRule"
          :loading="loading"
          @remove-product-clicked="removeProductFromDiscount"
          @fixed-price-updated="updateFixedPriceOnProduct"
          @discount-percentage-updated="updateDiscountPercentageOnProduct"
        />
      </div>
    </Panel>

    <Panel
      :header="t('discount.manufacturer-group-level.label')"
      toggleable
      :collapsed="panelCollapsedManufacturerGroup"
      class="mb-4 rounded-2xl"
      :pt:header:on-click="() => (panelCollapsedManufacturerGroup = !panelCollapsedManufacturerGroup)"
    >
      <ManufacturerGroupLevelDiscount
        v-model:manufacturer-group-level-discounts="discount.manufacturerGroupLevels"
        v-model:manufacturer-group-level-modal-visible="manufacturerGroupLevelModalVisible"
        :loading="loading"
        :selected-manufaturer-hierarchy="selectedManufaturerHierarchy"
        :all-available-manufacturers="allAvailableManufacturers"
        @open-manufacturer-group-level-modal="openManufacturerGroupLevelModal"
      />
    </Panel>

    <Panel
      :header="t('discount.customer.label')"
      toggleable
      :collapsed="panelCollapsedCustomer"
      class="mb-4 rounded-2xl"
      :pt:header:on-click="() => (panelCollapsedCustomer = !panelCollapsedCustomer)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-7 c-border-div">
          <div class="col-span-12 md:col-span-10 md:flex">
            <div class="col-span-12 md:col-span-4">
              <StatusCheckbox
                id="discount-active-for-all-customers"
                v-model:value="discount.activeForAllCustomers"
                :label="t(`discount.active-all-customers`)"
              />
            </div>
            <div class="col-span-12 md:col-span-6 w-1/3">
              <CustomerSearch
                :active-for-all-customers="discount.activeForAllCustomers"
                :selected-customers="selectedCustomers"
                @customer-selected="addCustomerToDiscount"
              />
            </div>
          </div>
          <div class="col-span-12 mt-2">
            <CustomerList
              :customers="selectedCustomers"
              :loading="loading"
              @remove-customer-clicked="removeCustomerFromDiscount"
            />
          </div>
        </div>
        <div class="col-span-12 lg:col-span-5">
          <div class="col-span-12">
            <div class="col-span-12 md:col-span-4 lg:col-span-6">
              <CustomerGroupSearch @customer-group-selected="addCustomerGroupToDiscount" />
            </div>
          </div>
          <div class="col-span-12 mt-1">
            <CustomerGroupList
              :customer-groups="selectedCustomerGroups"
              :loading="loading"
              @remove-customer-group-clicked="removeCustomerGroupFromDiscount"
            />
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";
import { NIL as emptyUuid } from "uuid";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import { CumulusDateTimePicker } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import useValidate from "@vuelidate/core";
import isEqual from "lodash.isequal";
import { useDiscountService } from "../api/price/DiscountService";
import { ProductWithDiscountInfo } from "../model/discount/ProductWithDiscountInfo";
import { Discount } from "../model/discount/Discount";
import { DiscountRule } from "../model/discount/DiscountRule";
import { DiscountRoutes } from "../routes";
import { SearchCustomer } from "../model/search/customer/SearchCustomer";
import { CustomerGroup } from "../model/customer-group/CustomerGroup";
import { discountProductFunctions } from "../utils/discountProductFunctions";
import { discountCustomerFunctions } from "../utils/discountCustomerFunctions";
import { discountCustomerGroupFunctions } from "../utils/discountCustomerGroupFunctions";
import { useLanguageStore } from "../stores/LanguageStore";
import { useProductHierarchyStore } from "../stores/ProductHierarchyStore";
import { Manufacturer } from "@multicase/portal-api/manufacturer";
import { ManufacturerGroupLevelInfo } from "../model/manufacturer/ManufacturerGroupLevelInfo";

const { t } = useI18n();
const val = useValidate();
const toast = useCumulusToast(useToast());
const router = useRouter();
const { addDiscount } = useDiscountService();
const { getLanguageIso } = useLanguageStore();
const { getAllProductHierarchies } = useProductHierarchyStore();
const { fetchManufacturers } = useManufacturers();

const discount = ref<Discount>(new Discount());
const initialDiscount = ref<Discount>(new Discount());
const isSaving = ref<boolean>(false);
const loading = ref<boolean>(false);
const selectedProducts = ref<ProductWithDiscountInfo[]>([]);
const selectedCustomers = ref<SearchCustomer[]>([]);
const selectedCustomerGroups = ref<CustomerGroup[]>([]);
const confirmedDiscard = ref(false);
const discountsCreated = ref<Discount[]>([]);
const discountId = ref("");

const allAvailableManufacturers = ref<Manufacturer[]>([]);

const {
  addProductToDiscountList,
  removeProductFromDiscount,
  updateDiscountPercentageOnProduct,
  updateFixedPriceOnProduct,
} = discountProductFunctions(discount, selectedProducts);

const { addCustomerToDiscount, removeCustomerFromDiscount } = discountCustomerFunctions(discount, selectedCustomers);

const { addCustomerGroupToDiscount, removeCustomerGroupFromDiscount } = discountCustomerGroupFunctions(
  discount,
  selectedCustomerGroups,
);

const onDiscountRuleChanged = (discountRule: DiscountRule) => {
  discount.value.products.forEach((product) => {
    product.discountRule = discountRule;
  });
  selectedProducts.value.forEach((product) => {
    product.discountRule = discountRule;
  });
};

const addNewDiscount = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("validations.summary"),
      detail: t("validations.detail"),
      closable: true,
    });
    return;
  }

  if (discount.value.id === emptyUuid) {
    discount.value.id = uuidv4();
  }

  try {
    isSaving.value = true;

    discountId.value = await addDiscount(discount.value);
    discountsCreated.value.push(discount.value);
    toast.add({
      severity: "success",
      summary: t("discount.added.summary"),
      detail: t("discount.added.detail", { name: discount.value.name }),
      closable: true,
    });

    resetForm();
  } finally {
    isSaving.value = false;
  }
};

const selectedManufaturerHierarchy = ref<ManufacturerGroupLevelInfo | null>(null);
const manufacturerGroupLevelModalVisible = ref(false);

const openManufacturerGroupLevelModal = (manufacturerGroupLevel: ManufacturerGroupLevelInfo | null) => {
  if (!manufacturerGroupLevel) {
    selectedManufaturerHierarchy.value = null;
  } else {
    selectedManufaturerHierarchy.value = manufacturerGroupLevel;
  }
  manufacturerGroupLevelModalVisible.value = true;
};

const resetForm = () => {
  val.value.$reset();
  discount.value = new Discount();
  selectedProducts.value = [];
  selectedCustomers.value = [];
  selectedCustomerGroups.value = [];
};

const routeToSearchPage = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null) {
    router.push({ name: DiscountRoutes.Search, query: { search: "" } });
  } else {
    router.back();
  }
};

const routeToUpdatedDiscount = () => {
  router.push({ name: DiscountRoutes.Update, params: { id: discountId.value } });
};

const hasUpdates = computed(() => {
  return !isEqual(discount.value, initialDiscount.value);
});

onMounted(async () => {
  try {
    loading.value = true;

    allAvailableManufacturers.value = await fetchManufacturers();

    await getLanguageIso();
    await getAllProductHierarchies();
  } finally {
    loading.value = false;
  }
});

const panelCollapsedProduct = ref(false);
const panelCollapsedManufacturerGroup = ref(false);
const panelCollapsedCustomer = ref(false);

const panelCollapsed = computed(() => {
  return panelCollapsedProduct.value && panelCollapsedManufacturerGroup.value && panelCollapsedCustomer.value;
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedProduct.value = newState;
  panelCollapsedManufacturerGroup.value = newState;
  panelCollapsedCustomer.value = newState;
};

useUnsavedChanges(hasUpdates);
</script>
