<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="
      selectedManufacturerGroupLevelInfo
        ? t('discount.manufacturer-group-level.edit-manufacturer-group-level-discount')
        : t('discount.manufacturer-group-level.add-manufacturer-group-level-discount')
    "
    :modal="true"
    class="w-4/12"
    :pt="{
      title: 'w-11/12 text-center',
    }"
  >
    <div class="grid grid-cols-12 gap-4 pt-2">
      <div class="col-span-12">
        <SelectManufacturer
          :manufacturerId="manufacturerGroupLevelInfo.manufacturerId"
          @manufacturerSelected="onManufacturerSelected"
        />
      </div>
      <div v-for="level in numberOfLevelsUsed" :key="level" class="col-span-12">
        <SelectGroupLevel
          :label="t(`discount.product-group.level-${level}`)"
          :selectedLevelId="getGroupLevelIdForSelectedLevel(level)"
          :productGroupNames="getOptionsForLevel(level, languageIso)"
          :level="level"
          @productGroupSelected="setGroupForLevel(level, $event)"
        />
      </div>
      <div class="col-span-4 col-start-9 pr-8 field">
        <label for="c-discount-manufacturer-discount-percentage-input">{{
          t("discount.manufacturer-group-level.discount") + " %"
        }}</label>
        <DiscountInput
          :id="`c-discount-manufacturer-discount-percentage-input`"
          inputClass="w-full"
          :minValue="0"
          :maxValue="100"
          :digits="2"
          :value="manufacturerGroupLevelInfo.discountPercentage"
          :allowEmpty="false"
          @valueUpdated="manufacturerGroupLevelInfo.discountPercentage = $event"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex justify-end flex-wrap card-container purple-container mt-4 mr-6">
        <PrimeButton
          :label="t('common.cancel')"
          class="mr-4"
          severity="cancel"
          variant="text"
          size="small"
          @click="visible = false"
          text
          icon="pi pi-times"
        />
        <PrimeButton
          :label="
            selectedManufacturerGroupLevelInfo
              ? t('discount.manufacturer-group-level.edit-discount')
              : t('discount.manufacturer-group-level.add-discount')
          "
          @click="onSaveManufacturerGroupLevelDiscount"
          data-testid="c-create-new-manufacturer-group-level-discount"
          icon="pi pi-check"
          variant="text"
          size="small"
        />
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import SelectManufacturer from "./SelectManufacturer.vue";
import SelectGroupLevel from "./SelectGroupLevel.vue";
import DiscountInput from "../DiscountInput.vue";
import { useI18n } from "vue-i18n";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useLanguageStore } from "../../stores/LanguageStore";
import { storeToRefs } from "pinia";
import { Manufacturer } from "../../model/manufacturer/Manufacturer";
import { ManufacturerGroupLevelInfo } from "../../model/manufacturer/ManufacturerGroupLevelInfo";
import { useProductGroupFunctions } from "../../utils/productGroupFunctions";
import { useProductHierarchyStore } from "../../stores/ProductHierarchyStore";
import cloneDeep from "lodash.clonedeep";

const visible = defineModel<boolean>("visible", { required: true });
const manufacturerGroupLevelInfo = ref<ManufacturerGroupLevelInfo>(new ManufacturerGroupLevelInfo());

const { t } = useI18n();
const { languageIso } = storeToRefs(useLanguageStore());
const { productHierarchies } = storeToRefs(useProductHierarchyStore());

const { numberOfLevelsUsed, setGroupForLevel, getGroupLevelIdForSelectedLevel, getOptionsForLevel } =
  useProductGroupFunctions(productHierarchies, manufacturerGroupLevelInfo);

const props = defineProps<{
  selectedManufacturerGroupLevelInfo: ManufacturerGroupLevelInfo | null;
}>();

const emit = defineEmits<{
  (event: "manufacturerGroupLevelAdded", value: ManufacturerGroupLevelInfo): void;
  (event: "manufacturerGroupLevelUpdated", value: ManufacturerGroupLevelInfo): void;
  (event: "manufacturerGroupLevelModalClosed"): void;
}>();

onMounted(async () => {
  if (props.selectedManufacturerGroupLevelInfo) {
    manufacturerGroupLevelInfo.value = cloneDeep(props.selectedManufacturerGroupLevelInfo);
  }
});

const onManufacturerSelected = (manufacturer: Manufacturer) => {
  manufacturerGroupLevelInfo.value.manufacturerId = manufacturer.id;
  manufacturerGroupLevelInfo.value.manufacturerName = manufacturer.name;
};

onBeforeUnmount(() => {
  emit("manufacturerGroupLevelModalClosed");
});

const onSaveManufacturerGroupLevelDiscount = () => {
  if (props.selectedManufacturerGroupLevelInfo) {
    emit("manufacturerGroupLevelUpdated", manufacturerGroupLevelInfo.value);
  } else {
    emit("manufacturerGroupLevelAdded", manufacturerGroupLevelInfo.value);
  }
  visible.value = false;
};
</script>
