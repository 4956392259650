<template>
  <DataTable
    ref="manufacturerSearchListRef"
    :value="manufacturers"
    striped-rows
    data-key="id"
    class="c-datatable"
    responsive-layout="scroll"
    selection-mode="single"
    scroll-height="400px"
    :loading="loading"
    :row-hover="true"
    data-testid="manufacturer-search-result"
    @row-select="onRowSelected"
  >
    <Column field="name" :header="t('discount.manufacturer-group-level.name')">
      <template #body="{ data, field, index }">
        <span :data-testid="`discount-manufacturer-search-result-name-${index}`">{{
          data[field as keyof typeof data]
        }}</span>
      </template>
    </Column>

    <template #empty>
      {{ t("discount.manufacturer-group-level.no-manufacturers-found") }}
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { type DataTableRowSelectEvent } from "primevue/datatable";
import { Manufacturer } from "@multicase/portal-api/manufacturer";

defineProps<{
  manufacturers: Manufacturer[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "manufacturerSelected", value: Manufacturer): void;
}>();

const { t } = useI18n();

const onRowSelected = (event: DataTableRowSelectEvent) => {
  emit("manufacturerSelected", event.data as Manufacturer);
};
</script>
