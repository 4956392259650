import { Customer, useCustomerApi } from "@multicase/portal-api/customer";
import type { AuthHeaders } from "@multicase/portal-api";

export function useCustomers() {
  const customers = ref<Customer[]>([]);

  const customerApi = useCustomerApi();
  const { getRequest, error, loading, saving } = useRequests();

  const getCustomersByIds = async (ids: string[]) => {
    return (
      (await getRequest(async (authHeaders: AuthHeaders) => {
        return await customerApi.getAllByIds(authHeaders, ids);
      })) ?? []
    );
  };

  return { customers, getCustomersByIds, loading, error, saving };
}
