<template>
  <div class="mt-5">
    <FloatLabel variant="on">
      <Select
        id="discount-type"
        v-model="selectedType"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('discount.type.label').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        :options="discountTypesComputed"
        option-label="name"
        data-testid="discount-type"
        option-value="value"
        class="w-full"
        :invalid="val.type.$error"
        pt:list:data-testid="discount-type-list"
      />

      <label for="discount-type">
        {{ t(`discount.type.label`) }}
      </label>
    </FloatLabel>
    <small v-if="val.type.$error" id="discount-type-help" data-testid="discount-type-error" class="p-error">{{
      val.type.$errors[0].$message
    }}</small>
  </div>
  <!-- Hack for miss PrimeVue css variables from CumulusComponents -->
  <Select style="display: none"></Select>
</template>

<script setup lang="ts">
import { required } from "../../i18n-validators";
import { DiscountType } from "../model/discount/DiscountType";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  type: string | null;
}>();

const emit = defineEmits<{
  (e: "update:type", value: string): void;
}>();

const selectedType = computed<string | null>({
  get: () => {
    return props.type ? (props.type as string) : null;
  },
  set: (value: string | null) => {
    if (!value) return;
    emit("update:type", value);
  },
});

const discountTypesComputed = computed(() => {
  const discountTypes = Object.values(DiscountType);
  return discountTypes.map((discountType) => {
    return {
      name: t(`discount.type.${discountType.toLowerCase()}`),
      value: discountType,
    };
  });
});

const rules = {
  type: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
