<template>
  <DiscountHeader
    :is-saving="isSaving"
    :is-editing="true"
    :is-deleting="isDeleting"
    :has-updates="hasUpdates"
    :discount-name="discount.name"
    :collapsed-panels="panelCollapsed"
    @save-clicked="onUpdateDiscount"
    @cancel-clicked="routeToSearchPage"
    @delete-clicked="onDeleteDiscount"
    @on-toggle-all-panels="onToggleAllPanels()"
  />

  <div v-if="loading" class="c-overlay">
    <div class="relative top-175">
      <ProgressSpinner />
    </div>
  </div>

  <div class="c-page-content mt-3 ml-4 pr-1">
    <div class="grid grid-cols-12 gap-6">
      <Card class="col-span-12 lg:col-span-8 mb-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <Name v-model:name="discount.name" />
              </div>
              <div>
                <StatusCheckbox id="discount-active" v-model:value="discount.active" :label="t(`discount.active`)" />
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <Description v-model:description="discount.description" />
              </div>

              <div>
                <div class="grid grid-cols-3 gap-4 pb-3">
                  <StatusCheckbox
                    id="discount-active-for-client"
                    v-model:value="discount.activeForClient"
                    :label="t(`discount.active-client`)"
                  />
                  <SelectClient v-model:client-id="discount.clientId" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4 mb-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <SelectDiscountType v-model:type="discount.discountType" />
              </div>
              <div>
                <CumulusDateTimePicker
                  id="active-from"
                  v-model:date-time="discount.activeFrom"
                  :label="t('discount.active-from')"
                  test-id="discount-active-from-date"
                ></CumulusDateTimePicker>
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <SelectDiscountRule
                  v-model:rule="discount.discountRule"
                  @discount-rule-changed="updateDiscountRuleForAllProducts"
                />
              </div>

              <div>
                <CumulusDateTimePicker
                  id="active-to"
                  v-model:date-time="discount.activeTo"
                  :label="t('discount.active-to')"
                  test-id="discount-active-to-date"
                ></CumulusDateTimePicker>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <Panel
      ref="productCollapsed"
      :header="t('discount.product.label')"
      toggleable
      :collapsed="panelCollapsedProduct"
      class="mb-4 rounded-2xl"
      :pt:header:on-click="() => (panelCollapsedProduct = !panelCollapsedProduct)"
    >
      <div class="col-span-12 lg:col-span-6 md:flex mb-4">
        <div class="col-span-12 md:col-span-3">
          <StatusCheckbox
            id="discount-active-for-all-products"
            v-model:value="discount.activeForAllProducts"
            :label="t(`discount.active-all-products`)"
            :disabled="true"
          />
        </div>
        <div class="col-span-12 md:col-span-4 w-1/5">
          <ProductSearch :discount-rule="discount.discountRule" @product-selected="addProductToDiscountList" />
        </div>
      </div>
      <div class="col-span-12 mt-2">
        <ProductList
          :products="selectedProducts"
          :discount-rule="discount.discountRule"
          :loading="loading"
          @remove-product-clicked="removeProductFromDiscount"
          @fixed-price-updated="updateFixedPriceOnProduct"
          @discount-percentage-updated="updateDiscountPercentageOnProduct"
        />
      </div>
    </Panel>

    <Panel
      class="mb-4 rounded-2xl"
      :header="t('discount.manufacturer-group-level.label')"
      toggleable
      :collapsed="panelCollapsedManufacturerGroup"
      :pt:header:on-click="() => (panelCollapsedManufacturerGroup = !panelCollapsedManufacturerGroup)"
    >
      <div class="col-span-12">
        <ManufacturerGroupLevelDiscount
          v-model:manufacturer-group-level-discounts="discount.manufacturerGroupLevels"
          v-model:manufacturer-group-level-modal-visible="manufacturerGroupLevelModalVisible"
          :existing-manufacturer-group-level-infos="selectedManufacturerGroupLevels"
          :loading="loading"
          :selected-manufaturer-hierarchy="selectedManufaturerHierarchy"
          :all-available-manufacturers="allAvailableManufacturers"
          @open-manufacturer-group-level-modal="openManufacturerGroupLevelModal"
        />
      </div>
    </Panel>

    <Panel
      class="mb-4 rounded-2xl"
      :header="t('discount.customer.label')"
      toggleable
      :collapsed="panelCollapsedCustomer"
      :pt:header:on-click="() => (panelCollapsedCustomer = !panelCollapsedCustomer)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-7 md:border-r pr-4">
          <div class="col-span-12 md:col-span-10 md:flex mb-4">
            <div class="col-span-12 md:col-span-4">
              <StatusCheckbox
                id="discount-active-for-all-customers"
                v-model:value="discount.activeForAllCustomers"
                :label="t(`discount.active-all-customers`)"
              />
            </div>
            <div class="col-span-12 md:col-span-6 w-1/3">
              <CustomerSearch
                :active-for-all-customers="discount.activeForAllCustomers"
                :selected-customers="selectedCustomers"
                @customer-selected="addCustomerToDiscount"
              />
            </div>
          </div>
          <div class="col-span-12 mt-2">
            <CustomerList
              :customers="selectedCustomers"
              :loading="loading"
              @remove-customer-clicked="removeCustomerFromDiscount"
            />
          </div>
        </div>
        <div class="col-span-12 lg:col-span-5">
          <div class="col-span-12">
            <div class="col-span-12 md:col-span-4 lg:col-span-6">
              <CustomerGroupSearch @customer-group-selected="addCustomerGroupToDiscount" />
            </div>
          </div>
          <div class="col-span-12 mt-1">
            <CustomerGroupList
              :customer-groups="selectedCustomerGroups"
              :loading="loading"
              @remove-customer-group-clicked="removeCustomerGroupFromDiscount"
            />
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script setup lang="ts">
import { useDiscountService } from "../api/price/DiscountService";
import useValidate from "@vuelidate/core";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import { ProductWithDiscountInfo } from "../model/discount/ProductWithDiscountInfo";
import { useI18n } from "vue-i18n";
import { Discount } from "../model/discount/Discount";
import { DiscountProduct } from "../model/discount/DiscountProduct";
import { DiscountRule } from "../model/discount/DiscountRule";
import { DiscountRoutes } from "../routes";
import { SearchCustomer } from "../model/search/customer/SearchCustomer";
import { useDuplicatedDataService } from "../api/price/DuplicatedDataService";
import { Product } from "../model/product/Product";
import { CustomerGroup } from "../model/customer-group/CustomerGroup";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { discountProductFunctions } from "../utils/discountProductFunctions";
import { discountCustomerFunctions } from "../utils/discountCustomerFunctions";
import { discountCustomerGroupFunctions } from "../utils/discountCustomerGroupFunctions";
import { DiscountManufacturerGroupLevel } from "../model/discount/DiscountManufacturerGroupLevel";
import { Manufacturer } from "@multicase/portal-api/manufacturer";
import { ManufacturerGroupLevelInfo } from "../model/manufacturer/ManufacturerGroupLevelInfo";
import { useProductService } from "../api/product/ProductService";
import { useLanguageStore } from "../stores/LanguageStore";
import { useProductHierarchyStore } from "../stores/ProductHierarchyStore";
import { storeToRefs } from "pinia";
import { ProductGroupNameByLanguage } from "../model/product/ProductGroupNameByLanguage";
import { NIL as emptyUuid } from "uuid";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const val = useValidate();
const toast = useCumulusToast(useToast());
const { getDiscountById, updateDiscount, deleteDiscount } = useDiscountService();
const { getCustomerGroupsByIds } = useDuplicatedDataService();
const { getCustomersByIds } = useCustomers();
const { getProductsByIds } = useProductService();
const { fetchManufacturersByIds, fetchManufacturers } = useManufacturers();
const { getLanguageIso } = useLanguageStore();
const { getAllProductHierarchies } = useProductHierarchyStore();
const { languageIso } = storeToRefs(useLanguageStore());

const { productHierarchies } = storeToRefs(useProductHierarchyStore());
const discount = ref<Discount>(new Discount());
const initialDiscount = ref<Discount>(new Discount());
const loading = ref<boolean>(false);
const isSaving = ref<boolean>(false);
const isDeleting = ref<boolean>(false);
const selectedProducts = ref<ProductWithDiscountInfo[]>([]);
const selectedCustomers = ref<SearchCustomer[]>([]);
const selectedCustomerGroups = ref<CustomerGroup[]>([]);
const selectedManufacturerGroupLevels = ref<ManufacturerGroupLevelInfo[]>([]);
const confirmedDiscard = ref(false);
const allAvailableManufacturers = ref<Manufacturer[]>([]);

const {
  addProductToDiscountList,
  removeProductFromDiscount,
  updateDiscountPercentageOnProduct,
  updateFixedPriceOnProduct,
} = discountProductFunctions(discount, selectedProducts);

const { addCustomerToDiscount, removeCustomerFromDiscount } = discountCustomerFunctions(discount, selectedCustomers);

const { addCustomerGroupToDiscount, removeCustomerGroupFromDiscount } = discountCustomerGroupFunctions(
  discount,
  selectedCustomerGroups,
);

const onDeleteDiscount = async () => {
  isDeleting.value = true;

  try {
    await deleteDiscount(discount.value.id);

    toast.add({
      severity: "success",
      summary: t("discount.deleted.summary"),
      detail: t("discount.deleted.detail", { name: discount.value.name }),
      closable: true,
    });

    routeToSearchPage();
  } finally {
    isDeleting.value = false;
  }
};

const onUpdateDiscount = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("validations.summary"),
      detail: t("validations.detail"),
      closable: true,
    });
    return;
  }

  try {
    isSaving.value = true;

    await updateDiscount(discount.value);

    toast.add({
      severity: "success",
      summary: t("discount.updated.summary"),
      detail: t("discount.updated.detail", { name: discount.value.name }),
      closable: true,
    });

    routeToSearchPage();
  } finally {
    isSaving.value = false;
  }
};

const selectedManufaturerHierarchy = ref<ManufacturerGroupLevelInfo | null>(null);
const manufacturerGroupLevelModalVisible = ref(false);

const openManufacturerGroupLevelModal = (manufacturerGroupLevel: ManufacturerGroupLevelInfo | null) => {
  if (!manufacturerGroupLevel) {
    selectedManufaturerHierarchy.value = null;
  } else {
    selectedManufaturerHierarchy.value = manufacturerGroupLevel;
  }
  manufacturerGroupLevelModalVisible.value = true;
};

const routeToSearchPage = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null) {
    router.push({ name: DiscountRoutes.Search, query: { search: "" } });
  } else {
    router.back();
  }
};

const updateDiscountRuleForAllProducts = (discountRule: DiscountRule) => {
  discount.value.products.forEach((product) => {
    product.discountRule = discountRule;
  });
  selectedProducts.value.forEach((product) => {
    product.discountRule = discountRule;
  });
};

const panelCollapsedProduct = ref(false);
const panelCollapsedManufacturerGroup = ref(false);
const panelCollapsedCustomer = ref(false);

const panelCollapsed = computed(() => {
  return panelCollapsedProduct.value && panelCollapsedManufacturerGroup.value && panelCollapsedCustomer.value;
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedProduct.value = newState;
  panelCollapsedManufacturerGroup.value = newState;
  panelCollapsedCustomer.value = newState;
};

const createDiscountedProductList = (
  discountProducts: DiscountProduct[],
  products: Product[],
): ProductWithDiscountInfo[] => {
  const productsWithDiscountInfo = [];

  for (const discountProduct of discountProducts) {
    for (const product of products) {
      if (discountProduct.id === product.id) {
        const discountProductList = ProductWithDiscountInfo.createFromProduct(
          product,
          discountProduct.discountRule,
          discountProduct.fixedPrice ?? 0,
          discountProduct.discountPercentage ?? 0,
        );
        productsWithDiscountInfo.push(discountProductList);
      }
    }
  }

  return productsWithDiscountInfo;
};

const createDiscountedManufacturerList = (
  discountManufacturerGroupLevels: DiscountManufacturerGroupLevel[],
  manufacturers?: Manufacturer[],
): ManufacturerGroupLevelInfo[] => {
  return discountManufacturerGroupLevels.flatMap((dmh) => {
    const manufacturer = manufacturers?.find((manufacturer) => manufacturer.id === dmh.manufacturerId);
    const manufacturerName = manufacturer ? manufacturer.name : "";

    const manufacturerGroupLevelInfo = new ManufacturerGroupLevelInfo();
    manufacturerGroupLevelInfo.id = dmh.id;
    manufacturerGroupLevelInfo.manufacturerId = dmh.manufacturerId;
    manufacturerGroupLevelInfo.manufacturerName = manufacturerName;
    manufacturerGroupLevelInfo.discountPercentage = dmh.discountPercentage;

    const level1 = productHierarchies.value.find((hierarchy) => hierarchy.level1?.id === dmh.groupLevel1Id)?.level1;
    const level2 = productHierarchies.value.find((hierarchy) => hierarchy.level2?.id === dmh.groupLevel2Id)?.level2;
    const level3 = productHierarchies.value.find((hierarchy) => hierarchy.level3?.id === dmh.groupLevel3Id)?.level3;
    const level4 = productHierarchies.value.find((hierarchy) => hierarchy.level4?.id === dmh.groupLevel4Id)?.level4;
    const level5 = productHierarchies.value.find((hierarchy) => hierarchy.level5?.id === dmh.groupLevel5Id)?.level5;

    const group1 = level1
      ? ProductGroupNameByLanguage.createFromProductGroupName(level1, languageIso.value)
      : new ProductGroupNameByLanguage();
    const group2 = level2
      ? ProductGroupNameByLanguage.createFromProductGroupName(level2, languageIso.value)
      : new ProductGroupNameByLanguage();
    const group3 = level3
      ? ProductGroupNameByLanguage.createFromProductGroupName(level3, languageIso.value)
      : new ProductGroupNameByLanguage();
    const group4 = level4
      ? ProductGroupNameByLanguage.createFromProductGroupName(level4, languageIso.value)
      : new ProductGroupNameByLanguage();
    const group5 = level5
      ? ProductGroupNameByLanguage.createFromProductGroupName(level5, languageIso.value)
      : new ProductGroupNameByLanguage();

    manufacturerGroupLevelInfo.groupLevel1 = group1 ?? manufacturerGroupLevelInfo.groupLevel1;
    manufacturerGroupLevelInfo.groupLevel2 = group2 ?? manufacturerGroupLevelInfo.groupLevel2;
    manufacturerGroupLevelInfo.groupLevel3 = group3 ?? manufacturerGroupLevelInfo.groupLevel3;
    manufacturerGroupLevelInfo.groupLevel4 = group4 ?? manufacturerGroupLevelInfo.groupLevel4;
    manufacturerGroupLevelInfo.groupLevel5 = group5 ?? manufacturerGroupLevelInfo.groupLevel5;

    return [manufacturerGroupLevelInfo];
  });
};

const fetchProductsFromDiscount = async () => {
  const productIds = discount.value.products.map((x) => x.id);

  if (productIds.length === 0) {
    return [];
  }

  const products = await getProductsByIds(productIds);

  if (products.length === 0) {
    return [];
  }

  return createDiscountedProductList(discount.value.products, products);
};

const fetchCustomersFromDiscount = async () => {
  if (discount.value.customerIds.length === 0) {
    return [];
  }

  const customers = await getCustomersByIds(discount.value.customerIds);
  if (customers.length === 0) {
    return [];
  }

  return discount.value.customerIds.map((customerId) => {
    return customers.find((customer) => customer.id === customerId) as SearchCustomer;
  });
};

const fetchCustomerGroupsFromDiscount = async () => {
  if (discount.value.customerGroupIds.length === 0) {
    return [];
  }

  const customerGroups = await getCustomerGroupsByIds(discount.value.customerGroupIds);

  if (customerGroups.length === 0) {
    return [];
  }

  return discount.value.customerGroupIds.map((customerGroupId) => {
    return customerGroups.find((customerGroup) => customerGroup.id === customerGroupId) as CustomerGroup;
  });
};

const fetchManufacturersFromDiscount = async () => {
  const manufacturerIds = discount.value.manufacturerGroupLevels
    .map((x) => x.manufacturerId)
    .filter((x) => x !== emptyUuid);

  if (manufacturerIds.length === 0) {
    return createDiscountedManufacturerList(discount.value.manufacturerGroupLevels);
  }

  const manufacturers = await fetchManufacturersByIds(manufacturerIds);
  if (manufacturers.length === 0) {
    return [];
  }
  return createDiscountedManufacturerList(discount.value.manufacturerGroupLevels, manufacturers);
};

onMounted(async () => {
  const discountId = route.params.id as string;
  loading.value = true;

  try {
    allAvailableManufacturers.value = await fetchManufacturers();
    discount.value = await getDiscountById(discountId);
    await getLanguageIso();
    await getAllProductHierarchies();

    const [productsInfo, customersInfo, customerGroups, manufacturers] = await Promise.all([
      fetchProductsFromDiscount(),
      fetchCustomersFromDiscount(),
      fetchCustomerGroupsFromDiscount(),
      fetchManufacturersFromDiscount(),
    ]);

    if (manufacturers.length === 0) {
      panelCollapsedManufacturerGroup.value = true;
    }
    if (productsInfo.length > 0) {
      selectedProducts.value = productsInfo;
    }

    if (customersInfo.length > 0) {
      selectedCustomers.value = customersInfo;
    }

    if (customerGroups.length > 0) {
      selectedCustomerGroups.value = customerGroups;
    }

    if (manufacturers.length > 0) {
      selectedManufacturerGroupLevels.value = manufacturers;
    }

    for (const product of selectedProducts.value) {
      if (product.discountRule === DiscountRule.FixedPrice) {
        updateFixedPriceOnProduct(product);
      }
      if (product.discountRule === DiscountRule.DiscountOnListPrice) {
        updateDiscountPercentageOnProduct(product);
      }
    }

    initialDiscount.value = cloneDeep(discount.value);
  } finally {
    loading.value = false;
  }
});

const hasUpdates = computed(() => {
  return !isEqual(discount.value, initialDiscount.value);
});

useUnsavedChanges(hasUpdates);
</script>
