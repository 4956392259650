<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <InputText
        id="discount-description"
        v-model="descriptionComputed"
        v-tooltip.focus.bottom="{
          value: t('placeholder.type', { property: t('discount.description').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="discount-description"
        class="w-full"
        :invalid="val.description.$error"
        maxlength="8000"
      />
      <label for="discount-description">{{ t("discount.description") }}</label>
    </FloatLabel>
    <small v-if="val.$error" class="p-error" data-testid="discount-description-error">
      {{ val.description.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { required } from "../../i18n-validators";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  description: string;
}>();

const emit = defineEmits<{
  (e: "update:description", value: string): void;
}>();

const { t } = useI18n();

const descriptionComputed = computed<string>({
  get: () => {
    return props.description ?? "";
  },
  set: (value: string) => {
    emit("update:description", value);
  },
});

const rules = {
  description: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
