<template>
  <Popover ref="chooserRef" data-testid="column-list">
    <DataTable
      v-model:selection="selectedColumns"
      :value="props.columns"
      dataKey="field"
      tableStyle="min-width: 16rem"
      responsiveLayout="stack"
      breakpoint="991px"
    >
      <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
      <Column field="header">
        <template #body="{ data }">
          <span data-testid="column-value-row">{{ t(data.header) }}</span>
        </template>
      </Column>
    </DataTable>
    <div class="flex justify-end">
      <PrimeButton
        type="button"
        :label="t('common.reset')"
        class="w-full m-4"
        severity="danger"
        text
        data-testid="reset-column-button"
        @click="emit('resetColumns')"
        autoOptionFocus="false"
        :tabindex="-1"
      ></PrimeButton>
    </div>
  </Popover>
</template>

<script setup lang="ts">
import { DataTableColumn, DataTableColumnExt } from "@cumulus/components";
import { ref } from "vue";

import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps<{
  columns: DataTableColumn[];
}>();

const selectedColumns = defineModel<DataTableColumn[] | DataTableColumnExt[]>("selectedColumns");

const emit = defineEmits<{
  (e: "resetColumns"): void;
  (e: "update:selectedColumns", value: DataTableColumn[] | DataTableColumnExt[]): void;
}>();

const chooserRef = ref();
defineExpose({
  toggle: (event: Event) => chooserRef.value.toggle(event),
});
</script>
