<template>
  <div class="col-span-12">
    <div class="flex flex-wrap my-3 pl-4">
      <div class="flex-1 min-w-full lg:min-w-0">
        <PrimeButton
          @click="emit('cancelClicked')"
          data-testid="header-cancel-button"
          id="header-cancel-button"
          size="large"
          variant="text"
        >
          <i class="pi pi-arrow-left"></i>
          <span class="px-2">{{ discountName }}</span>
        </PrimeButton>
      </div>
      <div class="flex-1 min-w-full lg:min-w-0 mb-4 lg:mb-0 text-right">
        <div v-tooltip.bottom="saveButtonTooltip" class="inline-block">
          <PrimeButton
            data-testid="header-save-button"
            class="c-circular-button mr-4"
            @click="emit('saveClicked')"
            id="header-save-button"
            variant="text"
            size="small"
            :disabled="isSaving || !hasUpdates"
          >
            <ProgressSpinner v-if="isSaving" class="c-spinner" />
            <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
            <span class="px-4">{{ t("common.save") }}</span>
          </PrimeButton>
        </div>

        <PrimeButton
          v-if="isEditing"
          class="c-circular-button mr-4"
          @click="onConfirmDelete"
          data-testid="header-delete-button"
          variant="text"
          size="small"
          :disabled="isDeleting"
        >
          <ProgressSpinner v-if="isDeleting" class="c-spinner" />
          <i v-else class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.delete") }}</span>
        </PrimeButton>
        <PrimeButton class="c-circular-button" @click="onToggleAllPanels" variant="text" size="small">
          <i class="pi pi-clone c-default-button c-circular-icon"></i>
          <span class="px-3">{{ collapsedPanels ? t("common.open-panels") : t("common.close-panels") }}</span>
        </PrimeButton>
        <ConfirmPopup data-testid="discount-delete-confirm"></ConfirmPopup>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('dialogCancelBtnClicked')"
    @discardClicked="emit('dialogDiscardBtnClicked')"
    @saveClicked="emit('saveClicked')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>

  <!-- Css Hack for PrimeVue -->
  <PrimeDialog></PrimeDialog>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { UnsavedChangesDialog } from "@cumulus/components";
import { computed } from "vue";

const props = defineProps<{
  isSaving: boolean;
  isDeleting?: boolean;
  isEditing: boolean;
  hasUpdates: boolean;
  unsavedChangesDialogVisible: boolean;
  collapsedPanels: boolean;
  discountName?: string;
}>();

const emit = defineEmits<{
  (e: "saveClicked"): void;
  (e: "cancelClicked"): void;
  (e: "deleteClicked"): void;
  (e: "dialogCancelBtnClicked"): void;
  (e: "dialogDiscardBtnClicked"): void;
  (e: "onToggleAllPanels", currentValue: boolean): void;
}>();

const { t } = useI18n();
const confirm = useConfirm();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("deleteClicked");
    },
  });
};

const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});

const onToggleAllPanels = () => {
  emit("onToggleAllPanels", props.collapsedPanels);
};
</script>

<style scoped lang="scss">
.c-spinner {
  width: 22.83px;
}

.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
