<template>
  <div class="c-page-content h-auto !overflow-hidden">
    <div class="grid grid-cols-12 my-4 ml-4">
      <div class="c-transition" :class="showSidebar && !isMobile ? 'col-span-9 2xl:col-span-10' : 'col-span-12'">
        <Card>
          <template #content>
            <div class="mx-4 my-0">
              <SearchDiscountList
                v-model:filters="filtersComputed"
                :discounts="discounts"
                :loading="loading"
                :total-hits="totalHits"
                :page-size="pageSize"
                :page="page"
                :sort-field="sortField"
                :sort-order="sortOrder"
                :employee-id="employeeId"
                :visible="showSidebar"
                @update:page="onUpdatePage"
                @update:sort-order="onUpdateSortOrder"
                @update:sort-field="onUpdateSortField"
                @row-dbl-clicked="openUpdateDiscount"
                @update:page-size="onUpdatePageSize"
                @refresh-list="search"
                @open-filter-sidebar="toggleFilterSidebar"
              />
            </div>
          </template>
        </Card>
      </div>

      <div v-if="isMobile">
        <Drawer v-model:visible="showSidebar" :header="t('common.filter')" position="right">
          <FilterSidebar v-model:filters="filtersComputed" :show-sidebar="showSidebar" data-testId="c-right-sidebar" />
        </Drawer>
      </div>

      <div
        v-else
        class="transition-all duration-300 ease-in-out"
        :class="showSidebar ? 'col-span-3 2xl:col-span-2' : 'w-0 hidden'"
      >
        <Card class="ml-4 min-h-full" data-testid="c-right-sidebar">
          <template #content>
            <div class="c-filter-header-container">
              <i class="pi pi-sliders-h"></i>
              <span class="ml-4 font-semibold text-xl">{{ t("common.filter") }}</span>
            </div>

            <FilterSidebar v-model:filters="filtersComputed" :show-sidebar="showSidebar" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { DiscountSearchFilters } from "../model/search/discount/DiscountSearchFilters";
import FilterSidebar from "../components/FilterSidebar.vue";
import SearchDiscountList from "../components/SearchDiscountList.vue";
import { SearchDiscount } from "../model/search/discount/SearchDiscount";
import { DiscountSearchRequest } from "../model/search/discount/DiscountSearchRequest";
import { useRoute, useRouter } from "vue-router";
import { useSearchService } from "../api/search/SearchService";
import { DiscountRoutes } from "../routes";
import { useAuth } from "@cumulus/event-bus";
import { NIL as emptyUuid } from "uuid";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { useI18n } from "vue-i18n";
import { useMobile } from "../utils/MobileService";

const toast = useCumulusToast(useToast());
const { getUser } = useAuth();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { discountSearch } = useSearchService();

const discounts = ref<SearchDiscount[]>([]);
const showSidebar = ref(false);
const filters = ref(new DiscountSearchFilters());
const loading = ref(false);
const totalHits = ref(0);
const pageSize = ref<number>(50);
const page = ref(1);
const query = ref("");
const sortField = ref("");
const sortOrder = ref(-1);
const { isMobile } = useMobile();
watch(isMobile, (value) => {
  if (value == true) showSidebar.value = false;
});

watch(
  () => route.query.search,
  async (newQuery) => {
    if (newQuery !== undefined) {
      query.value = newQuery as string;
      loading.value = true;
      await search();
    }
  },
);

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const toggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
  localStorage.setItem(`user-filter-discountsearch-${employeeId.value}`, JSON.stringify(showSidebar.value));
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const search = async () => {
  try {
    loading.value = true;
    const request = new DiscountSearchRequest(query.value);

    request.page = page.value;
    request.pageSize = pageSize.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    request.filters = filters.value;
    const response = await discountSearch(request);
    discounts.value = response.discounts;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const openUpdateDiscount = (discountId: string) => {
  discountId != null && discountId !== emptyUuid
    ? router.push({ name: DiscountRoutes.Update, params: { id: discountId } })
    : toast.add({
        severity: "warn",
        summary: t("discount.not-found"),
        detail: t("discount.not-found"),
        closable: true,
      });
};

const filtersComputed = computed<DiscountSearchFilters>({
  get: () => filters.value,
  set: async (value: DiscountSearchFilters) => {
    filters.value = value;
    loading.value = true;
    await search();
  },
});

const employeeId = ref<string>("");
onMounted(async () => {
  employeeId.value = (await getUser()).getEmployee().id;
  const active = localStorage.getItem(`user-filter-discountsearch-${employeeId.value}`);
  if (active !== null) {
    showSidebar.value = JSON.parse(active);
  }

  query.value = decodeURIComponent((route.query.search as string) ?? "");
  await search();
});
</script>
