<template>
  <div v-click-outside="closeDropdown">
    <FloatLabel variant="on">
      <Select
        v-model="selectedProductGroupName"
        :options="productGroupNames"
        overlayClass="hidden"
        optionLabel="name"
        :data-testid="`c-discount-product-group-dropdown-${level}`"
        class="w-full"
        @click="toggleProductGroupSearch"
        v-tooltip.bottom="{
          value: t('placeholder.select', {
            property: t('discount.manufacturer-group-level.group-level').toLowerCase(),
          }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />
      <label :for="`c-discount-product-group-dropdown-${level}`">
        {{ label }}
      </label>
    </FloatLabel>
    <div v-show="showProductGroupSearchList" class="c-product-group-dropdown">
      <GroupLevelSearchList
        ref="groupLevelSearchListRef"
        :productGroupNames="productGroupNames"
        :productHierarchies="productHierarchies"
        :loading="loading"
        :level="level"
        @productGroupSelected="onProductGroupSelected"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, nextTick, ref } from "vue";
import GroupLevelSearchList from "./GroupLevelSearchList.vue";
import { ProductGroupNameByLanguage } from "../../model/product/ProductGroupNameByLanguage";
import { ProductHierarchy } from "../../model/product/ProductHierarchy";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  label: string;
  selectedLevelId: string;
  productGroupNames: ProductGroupNameByLanguage[];
  level: number;
}>();

const emit = defineEmits<{
  (event: "productGroupSelected", data: ProductGroupNameByLanguage): void;
}>();

const { t } = useI18n();
const loading = ref(false);
const productHierarchies = ref<ProductHierarchy[]>([]);
const showProductGroupSearchList = ref<boolean>(false);
const groupLevelSearchListRef = ref();

const selectedProductGroupName = computed(() => {
  const selectedProductGroup = props.productGroupNames.find(
    (productGroup) => productGroup.id === props.selectedLevelId
  );
  return selectedProductGroup;
});

const toggleProductGroupSearch = () => {
  showProductGroupSearchList.value = !showProductGroupSearchList.value;
  if (showProductGroupSearchList.value) {
    nextTick(() => {
      groupLevelSearchListRef.value.focusSearchInput();
    });
  }
};

const closeDropdown = () => {
  showProductGroupSearchList.value = false;
};

const onProductGroupSelected = (data: ProductGroupNameByLanguage) => {
  showProductGroupSearchList.value = false;
  emit("productGroupSelected", data);
};
</script>

<style scoped lang="scss">
.c-product-group-dropdown {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--surface-section);
  border-top: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.dark .c-product-group-dropdown {
  border: 1px solid var(--p-surface-600);
  padding: 0px;
  background-color: var(--p-surface-950);
}
</style>
