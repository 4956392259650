<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <InputText
        id="discount-name"
        v-model="nameComputed"
        v-debounce:300="checkIfDiscountNameIsInUse"
        v-tooltip.focus.bottom="{
          value: t('placeholder.type', { property: t('discount.name').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="discount-name"
        class="w-full"
        :invalid="val.$error"
        :class="{ 'c-invalid': isDiscountNameInUse }"
        maxlength="8000"
        autofocus
      />
      <label for="discount-name">{{ t("discount.name") }}</label>
    </FloatLabel>
    <small v-if="val.$error" class="p-error" data-testid="discount-name-error">
      {{ val.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDiscountService } from "@/discount/api/price/DiscountService";
import { helpers } from "@vuelidate/validators";
import { required } from "../../i18n-validators";

const { getDiscountByName } = useDiscountService();
const isDiscountNameInUse = ref<boolean>(false);

const props = defineProps<{
  name: string;
}>();

const emit = defineEmits<{
  (e: "update:name", value: string): void;
}>();

const { t } = useI18n();

const nameComputed = computed<string>({
  get: () => {
    return props.name ?? "";
  },
  set: (value: string) => {
    emit("update:name", value);
  },
});

const checkIfDiscountNameIsInUse = async () => {
  if (nameComputed.value === "") {
    isDiscountNameInUse.value = false;
    return;
  }

  try {
    const discountNameInUse = await getDiscountByName(nameComputed.value);
    isDiscountNameInUse.value = Array.isArray(discountNameInUse) && discountNameInUse.length !== 0;
  } catch {
    isDiscountNameInUse.value = false;
  }
};

const rules = {
  name: {
    required: required,
  },
  isDiscountNameInUse: {
    discountNameAvailable: helpers.withMessage(t("discount.name-in-use"), () => !isDiscountNameInUse.value),
  },
};

const val = useVuelidate(rules, { name: nameComputed, isDiscountNameInUse });
</script>
