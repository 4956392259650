<template>
  <InputNumber
    :id="id"
    :input-class="inputClass"
    :model-value="value"
    :suffix="suffix"
    :allow-empty="allowEmpty"
    :min="minValue"
    :max="maxValue"
    :min-fraction-digits="digits"
    :max-fraction-digits="digits"
    :locale="locale"
    :invalid="invalid"
    :data-testid="id"
    @focus="selectAllTextOnFocus"
    @update:model-value="onUpdate($event)"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const props = defineProps<{
  id: string;
  value: number;
  inputClass?: string | undefined;
  digits?: number | undefined;
  minValue?: number | undefined;
  maxValue?: number | undefined;
  suffix?: string | undefined;
  allowEmpty?: boolean | undefined;
  invalid?: boolean | undefined;
}>();

const emit = defineEmits<{
  (event: "valueUpdated", value: number): void;
}>();

const { locale } = useI18n();

const selectAllTextOnFocus = (event: Event) => {
  (event.target as HTMLInputElement).select();
};

const onUpdate = (value: number) => {
  const amount = props.value;
  const options = {
    style: "decimal" as const,
    minimumFractionDigits: props.digits,
    maximumFractionDigits: props.digits,
  };
  // Using en-US because else we can't convert the string to a number properly
  const numberFormat = new Intl.NumberFormat("en-US", options);
  const formattedValue = parseFloat(numberFormat.format(amount));

  if (value != null && value !== formattedValue) {
    emit("valueUpdated", value);
  }
};
</script>
