<template>
  <div class="formgrid grid grid-cols-12 gap-4 mt-6">
    <div class="col-span-12 text-center">
      <CumulusDatePicker
        id="filter-registered-from-date"
        v-model:date="registeredFromDateComputed"
        :label="t('discount.registered-from')"
        test-id="filter-registered-from-date"
        :debounce-time="400"
      />
    </div>
    <div class="col-span-12 field text-center">
      <CumulusDatePicker
        id="filter-registered-to-date"
        v-model:date="registeredToDateComputed"
        :label="t('discount.registered-to')"
        test-id="filter-registered-to-date"
        :debounce-time="400"
      />
    </div>
  </div>
  <div class="formgrid grid grid-cols-12 gap-4 mt-6">
    <div class="col-span-12 field text-center">
      <CumulusDatePicker
        id="filter-active-from-date"
        v-model:date="activeFromDateComputed"
        :label="t('discount.active-from')"
        test-id="filter-active-from-date"
        :debounce-time="400"
      />
    </div>
    <div class="col-span-12 field text-center">
      <CumulusDatePicker
        id="filter-active-to-date"
        v-model:date="activeToDateComputed"
        :label="t('discount.active-to')"
        test-id="filter-active-to-date"
        :debounce-time="400"
      />
    </div>
  </div>
  <hr class="mt-6 mb-6" />
  <DiscountRuleCheckboxList v-model:selected-discount-rules="discountRulesComputed" />
  <hr class="mt-6 mb-6" />
  <DiscountStatusCheckboxList v-model:selected-discount-statuses="discountStatusesComputed" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import DiscountRuleCheckboxList from "./DiscountRuleCheckboxList.vue";
import DiscountStatusCheckboxList from "./DiscountStatusCheckboxList.vue";
import { DiscountSearchFilters } from "../model/search/discount/DiscountSearchFilters";
import { DiscountStatus } from "../model/search/discount/DiscountStatus";
import { DiscountRule } from "../model/discount/DiscountRule";
import { CumulusDatePicker } from "@cumulus/components";

const props = defineProps<{
  showSidebar: boolean;
  filters: DiscountSearchFilters;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: DiscountSearchFilters): void;
}>();

const { t } = useI18n();

const registeredFromDateComputed = computed({
  get: () => (props.filters.registeredFromDate ? props.filters.registeredFromDate : ""),
  set: (value) => {
    const fromDate = value ? new Date(value).toJSON() : null;
    const filters = { ...props.filters, registeredFromDate: fromDate };
    emit("update:filters", filters);
  },
});
const registeredToDateComputed = computed({
  get: () => (props.filters.registeredToDate ? props.filters.registeredToDate : ""),
  set: (value) => {
    const toDate = value ? new Date(value).toJSON() : null;
    const filters = { ...props.filters, registeredToDate: toDate };
    emit("update:filters", filters);
  },
});

const activeFromDateComputed = computed({
  get: () => (props.filters.activeFromDate ? props.filters.activeFromDate : ""),
  set: (value) => {
    const fromDate = value ? new Date(value).toJSON() : null;
    const filters = { ...props.filters, activeFromDate: fromDate };
    emit("update:filters", filters);
  },
});

const activeToDateComputed = computed({
  get: () => (props.filters.activeToDate ? props.filters.activeToDate : ""),
  set: (value) => {
    const toDate = value ? new Date(value).toJSON() : null;
    const filters = { ...props.filters, activeToDate: toDate };
    emit("update:filters", filters);
  },
});

const discountStatusesComputed = computed<DiscountStatus[]>({
  get: () => props.filters.discountStatuses,
  set: (value: DiscountStatus[]) => {
    const filters: DiscountSearchFilters = { ...props.filters, discountStatuses: value };
    emit("update:filters", filters);
  },
});

const discountRulesComputed = computed<DiscountRule[]>({
  get: () => props.filters.discountRules,
  set: (value: DiscountRule[]) => {
    const filters: DiscountSearchFilters = { ...props.filters, discountRules: value };
    emit("update:filters", filters);
  },
});
</script>
