import { useManufacturerApi } from "@multicase/portal-api/manufacturer";
import { Manufacturer } from "@multicase/portal-api/manufacturer";
import type { AuthHeaders } from "@multicase/portal-api";

export function useManufacturers() {
  const manufacturer = ref<Manufacturer>(new Manufacturer());
  const manufacturers = ref<Manufacturer[]>([]);

  const manufacturerApi = useManufacturerApi();
  const { getRequest, loading, saving, error } = useRequests();

  const fetchManufacturers = async (): Promise<Manufacturer[]> => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      manufacturers.value = await manufacturerApi.getAll(authHeaders);
    });
    return manufacturers.value;
  };

  const fetchManufacturersByIds = async (ids: string[]): Promise<Manufacturer[]> => {
    return (
      (await getRequest(async (authHeaders: AuthHeaders) => {
        return await manufacturerApi.getAllByIds(authHeaders, ids);
      })) ?? []
    );
  };

  const fetchActiveManufacturersByName = async (name: string): Promise<Manufacturer[]> => {
    const result = await getRequest(async (authHeaders: AuthHeaders) => {
      return await manufacturerApi.getByName(authHeaders, name);
    });
    return result ?? [];
  };

  onMounted(fetchManufacturers);

  return {
    manufacturers,
    manufacturer,
    fetchManufacturers,
    fetchManufacturersByIds,
    fetchActiveManufacturersByName,
    loading,
    saving,
    error,
  };
}
